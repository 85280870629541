<template>
  <div class="DialogReportHistory__Component">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">היסטורית אירועים</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div v-if="rows">
          <table class="table">
            <thead>
              <tr>
                <th :key="index" v-for="(header, index) in rows.keys">
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tr v-for="(row, index) in rows.values" :key="index">
              <td :key="index" v-for="(value, index) in row">{{ value }}</td>
            </tr>
          </table>
        </div>
        <h1 v-if="!rows">אין אירועים</h1>
      </section>
      <footer class="modal-card-foot">
        <b-button label="סגור" @click="$emit('close')" />
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "DialogReportHistory",
  props: ["data"],
  computed: {
    rows() {
      return (
        this.data.Items[0] && {
          keys: Object.keys(this.data.Items[0]),
          values: this.data.Items.map((i) => Object.values(i)),
          //   values: this.data.Items.map((i) => {
          //     const arrVal = Object.values(i);
          //     // const arrKey = Object.keys(i);
          //     // arrKey.forEach((k, idx) => {
          //     //   if (
          //     //     k === "RentalPickUpFormHtml" ||
          //     //     k === "RentalReturnFormHtml" ||
          //     //     k === "DriversLicenseHtml" ||
          //     //     k === "TransferHtml"
          //     //   ) {
          //     //     arrVal[idx] = !!arrVal[idx];
          //     //   }
          //     // });
          //     return arrVal;
          //   }),
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.DialogReportHistory__Component {
  ::v-deep .modal-card {
    width: 100% !important;
  }
}
</style>;
