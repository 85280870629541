var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogReportHistory__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v("היסטורית אירועים")
        ]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c("section", { staticClass: "modal-card-body" }, [
        _vm.rows
          ? _c("div", [
              _c(
                "table",
                { staticClass: "table" },
                [
                  _c("thead", [
                    _c(
                      "tr",
                      _vm._l(_vm.rows.keys, function(header, index) {
                        return _c("th", { key: index }, [
                          _vm._v(" " + _vm._s(header) + " ")
                        ])
                      }),
                      0
                    )
                  ]),
                  _vm._l(_vm.rows.values, function(row, index) {
                    return _c(
                      "tr",
                      { key: index },
                      _vm._l(row, function(value, index) {
                        return _c("td", { key: index }, [_vm._v(_vm._s(value))])
                      }),
                      0
                    )
                  })
                ],
                2
              )
            ])
          : _vm._e(),
        !_vm.rows ? _c("h1", [_vm._v("אין אירועים")]) : _vm._e()
      ]),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: { label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }